class ImageEditor {

    constructor() {

    }

    combineImages(armorUrl, trimUrl, paletteUrl) {
        return new Promise((resolve, reject) => {

            const armorImg = new Image();
            const trimImg = new Image();
            const paletteImg = new Image();

            armorImg.src = armorUrl;
            trimImg.src = trimUrl;
            paletteImg.src = paletteUrl;

            Promise.all([new Promise(resolve => armorImg.onload = resolve), new Promise(resolve => trimImg.onload = resolve), new Promise(resolve => paletteImg.onload = resolve)]).then(() => {
                const canvas = document.createElement('canvas');
                const trimCanvas = document.createElement('canvas');
                canvas.width = armorImg.width;
                canvas.height = armorImg.height;

                const ctx = canvas.getContext('2d');
                const trimCtx = trimCanvas.getContext('2d');

                ctx.drawImage(armorImg, 0, 0);
                ctx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);
                trimCtx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);

                const trimImageData = ctx.getImageData(0, 0, trimImg.width, trimImg.height);
                const splitFullImg = trimImageData.data;
                const splitTrimImg = trimCtx.getImageData(0, 0, trimImg.width, trimImg.height).data;

                const paletteCanvas = document.createElement('canvas');
                paletteCanvas.width = paletteImg.width;
                paletteCanvas.height = paletteImg.height;
                const paletteCtx = paletteCanvas.getContext('2d');
                paletteCtx.drawImage(paletteImg, 0, 0);
                const paletteData = paletteCtx.getImageData(0, 0, paletteImg.width, paletteImg.height).data;

                for (let i = 0; i < splitTrimImg.length; i += 4) {
                    const grayValue = splitTrimImg[i];
                    const opacity = splitTrimImg[i + 3];

                    if (opacity == 0)
                        continue;

                    // 8
                    const colorIndex = 7 - grayValue / 32;

                    splitFullImg[i] = paletteData[colorIndex * 4];
                    splitFullImg[i + 1] = paletteData[colorIndex * 4 + 1];
                    splitFullImg[i + 2] = paletteData[colorIndex * 4 + 2];
                }

                ctx.putImageData(trimImageData, 0, 0);
                const dataURL = canvas.toDataURL();
                resolve(dataURL);

            }).catch(reject);
        });
    }

    applyPallete(trimUrl, paletteUrl) {
        return new Promise((resolve, reject) => {

            const trimImg = new Image();
            const paletteImg = new Image();

            trimImg.src = trimUrl;
            paletteImg.src = paletteUrl;

            Promise.all([new Promise(resolve => trimImg.onload = resolve), new Promise(resolve => paletteImg.onload = resolve)]).then(() => {
                const canvas = document.createElement('canvas');
                const trimCanvas = document.createElement('canvas');
                canvas.width = trimImg.width;
                canvas.height = trimImg.height;

                const ctx = canvas.getContext('2d');
                const trimCtx = trimCanvas.getContext('2d');

                ctx.drawImage(armorImg, 0, 0);
                ctx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);
                trimCtx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);

                const trimImageData = ctx.getImageData(0, 0, trimImg.width, trimImg.height);
                const splitFullImg = trimImageData.data;
                const splitTrimImg = trimCtx.getImageData(0, 0, trimImg.width, trimImg.height).data;

                const paletteCanvas = document.createElement('canvas');
                paletteCanvas.width = paletteImg.width;
                paletteCanvas.height = paletteImg.height;
                const paletteCtx = paletteCanvas.getContext('2d');
                paletteCtx.drawImage(paletteImg, 0, 0);
                const paletteData = paletteCtx.getImageData(0, 0, paletteImg.width, paletteImg.height).data;

                for (let i = 0; i < splitTrimImg.length; i += 4) {
                    const grayValue = splitTrimImg[i];
                    const opacity = splitTrimImg[i + 3];

                    if (opacity == 0)
                        continue;

                    // 8
                    const colorIndex = 7 - grayValue / 32;

                    splitFullImg[i] = paletteData[colorIndex * 4];
                    splitFullImg[i + 1] = paletteData[colorIndex * 4 + 1];
                    splitFullImg[i + 2] = paletteData[colorIndex * 4 + 2];
                }

                ctx.putImageData(trimImageData, 0, 0);
                const dataURL = canvas.toDataURL();
                resolve(dataURL);

            }).catch(reject);
        });
    }

    combineImages2(imageUrls) {
        return new Promise((resolve, reject) => {

            let images = []

            for (const imageUrl of imageUrls) {

                const img = new Image();
                img.src = imageUrl;
                images.push(img);
            }

            let promises = [];

            for (const image of images) {

                promises.push(new Promise(resolve => image.onload = resolve));
            }

            Promise.all(promises).then(() => {

                const canvas = document.createElement('canvas');
                const trimCanvas = document.createElement('canvas');
                canvas.width = armorImg.width;
                canvas.height = armorImg.height;

                const ctx = canvas.getContext('2d');
                const trimCtx = trimCanvas.getContext('2d');

                ctx.drawImage(armorImg, 0, 0);
                ctx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);
                trimCtx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);

                const trimImageData = ctx.getImageData(0, 0, trimImg.width, trimImg.height);
                const splitFullImg = trimImageData.data;
                const splitTrimImg = trimCtx.getImageData(0, 0, trimImg.width, trimImg.height).data;

                const paletteCanvas = document.createElement('canvas');
                paletteCanvas.width = paletteImg.width;
                paletteCanvas.height = paletteImg.height;
                const paletteCtx = paletteCanvas.getContext('2d');
                paletteCtx.drawImage(paletteImg, 0, 0);
                const paletteData = paletteCtx.getImageData(0, 0, paletteImg.width, paletteImg.height).data;

                for (let i = 0; i < splitTrimImg.length; i += 4) {
                    const grayValue = splitTrimImg[i];
                    const opacity = splitTrimImg[i + 3];

                    if (opacity == 0)
                        continue;

                    // 8
                    const colorIndex = 7 - grayValue / 32;

                    splitFullImg[i] = paletteData[colorIndex * 4];
                    splitFullImg[i + 1] = paletteData[colorIndex * 4 + 1];
                    splitFullImg[i + 2] = paletteData[colorIndex * 4 + 2];
                }

                ctx.putImageData(trimImageData, 0, 0);
                const dataURL = canvas.toDataURL();
                resolve(dataURL);

            }).catch(reject);

            // Promise.all([new Promise(resolve => armorImg.onload = resolve), new Promise(resolve => trimImg.onload = resolve), new Promise(resolve => paletteImg.onload = resolve)]).then(() => {
            //     const canvas = document.createElement('canvas');
            //     const trimCanvas = document.createElement('canvas');
            //     canvas.width = armorImg.width;
            //     canvas.height = armorImg.height;

            //     const ctx = canvas.getContext('2d');
            //     const trimCtx = trimCanvas.getContext('2d');

            //     ctx.drawImage(armorImg, 0, 0);
            //     ctx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);
            //     trimCtx.drawImage(trimImg, 0, 0, armorImg.width, armorImg.height);

            //     const trimImageData = ctx.getImageData(0, 0, trimImg.width, trimImg.height);
            //     const splitFullImg = trimImageData.data;
            //     const splitTrimImg = trimCtx.getImageData(0, 0, trimImg.width, trimImg.height).data;

            //     const paletteCanvas = document.createElement('canvas');
            //     paletteCanvas.width = paletteImg.width;
            //     paletteCanvas.height = paletteImg.height;
            //     const paletteCtx = paletteCanvas.getContext('2d');
            //     paletteCtx.drawImage(paletteImg, 0, 0);
            //     const paletteData = paletteCtx.getImageData(0, 0, paletteImg.width, paletteImg.height).data;

            //     for (let i = 0; i < splitTrimImg.length; i += 4) {
            //         const grayValue = splitTrimImg[i];
            //         const opacity = splitTrimImg[i + 3];

            //         if (opacity == 0)
            //             continue;

            //         // 8
            //         const colorIndex = 7 - grayValue / 32;

            //         splitFullImg[i] = paletteData[colorIndex * 4];
            //         splitFullImg[i + 1] = paletteData[colorIndex * 4 + 1];
            //         splitFullImg[i + 2] = paletteData[colorIndex * 4 + 2];
            //     }

            //     ctx.putImageData(trimImageData, 0, 0);
            //     const dataURL = canvas.toDataURL();
            //     resolve(dataURL);

            // }).catch(reject);
        });
    }
}

const modelEditCanvas = document.createElement("canvas");


function updModel(object, textureUrl) {

    const img = new Image();

    img.addEventListener("load", () => {

        modelEditCanvas.width = img.width;
        modelEditCanvas.height = img.height;
        const ctx = modelEditCanvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        for (let i = 0; i < object.geometry.attributes.position.count; i++) {

            const uvX = object.geometry.attributes.uv.getX(i);
            const uvY = object.geometry.attributes.uv.getY(i);
            const pixelData = ctx.getImageData(uvX * img.width, img.height - uvY * img.height, 1, 1).data;

            if (pixelData[3] == 0) {

                object.geometry.attributes.position.setY(i, 300);
                object.geometry.attributes.position.setX(i, 300);
                object.geometry.attributes.position.setZ(i, 300);
            }
        }
        object.geometry.attributes.position.needsUpdate = true;
    })
    img.src = textureUrl;
}
